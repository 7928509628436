.container {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	width: 100vw;
	height: 100vh;

	padding: 30px 0;
	display: flex;
	align-items: center;
	font-family: DM Sans;
	min-height: 100vh;
	background-color: #060606;
	color: #ffffff;
	overflow: hidden;

	.info {
		h1 {
			font-weight: 700;
			font-size: 60px;
			line-height: 78px;
			letter-spacing: 0.2px;
			margin-bottom: 8px;
		}
		h2 {
			font-weight: 400;
			font-size: 30px;
			line-height: 40px;
			letter-spacing: 0.2px;
			margin-bottom: 27px;
		}
		h3 {
			font-weight: 400;
			font-size: 15px;
			line-height: 20px;
			letter-spacing: 0.2px;
			color: #ffffff99;
		}
	}

	.image-group {
		position: relative;
		height: fit-content;
		display: flex;
		align-items: center;
		transform: scale(1.4);

		.image-1 {
			// position: absolute;
			width: 100%;
			height: auto;
		}
		.image-2 {
			position: absolute;
			left: 12.5%;
			bottom: 28.5%;
			width: 25%;
			height: auto;
		}
	}

	@media only screen and (max-width: @screen-mobile-max) {
		.info {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;

			h1 {
				font-size: 30px;
				line-height: 39px;
			}
			h2 {
				font-size: 20px;
				line-height: 25px;
				margin-bottom: 15px;
			}
			h3 {
				font-size: 12px;
				line-height: 15px;
			}
		}
		.image-group {
			transform: scale(1);
		}
	}
}


@import '/home/eff/app/creoplay-web/src/styles/variables.less';